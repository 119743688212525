$(document).ready(function() {
    var $widget = $(".video-widget");
    var $widgetVideo = document.getElementById("video-widget__video");

    $(".video-widget__close").click(function (e) {
        e.preventDefault();

        var state = $widget.attr("data-state");

        if(state == "default") {
            $widget.hide();
        } else {
            $widget.attr("data-state", "default");
            $widgetVideo.muted = true;
        }

        return false;
    });

    $(".video-widget__container").on("click", function (e) {
        var state = $widget.attr("data-state");

        if(state == "default") {
            $widget.attr("data-state", "opened");
            $widgetVideo.currentTime = 0;
            $widgetVideo.muted = false;
        } else {
            $widget.attr("data-state", "default");
            $widgetVideo.muted = true;
        }
    });

    if($(document).width() > 1024) {
        $(".video-widget__container").on("touchstart", function (e) {
            var state = $widget.attr("data-state");

            if(state == "default") {
                $widget.attr("data-state", "opened");
                $widgetVideo.currentTime = 0;
                $widgetVideo.muted = false;
            } else {
                $widget.attr("data-state", "default");
                $widgetVideo.muted = true;
            }
        });
    }

    $(document).mouseup(function (e) {
        if (!$widget.is(e.target)
            && $widget.has(e.target).length === 0 && $widget.attr("data-state") != "default") {
            $widget.attr("data-state", "default");
            $widgetVideo.muted = true;
        }
    });
});